import * as React from 'react';

import { Layout } from '../components/Layout';

const IndexPage = () => (
  <Layout>
    <h1 className="text-lg">Coming soon...</h1>
  </Layout>
);

export default IndexPage;
